import { FC } from 'react';
import IconProps from './IconProps';

const BuildingIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 12H14V14H16M16 8H14V10H16M18 16H10V14H12V12H10V10H12V8H10V6H18M8 4H6V2H8M8 8H6V6H8M8 12H6V10H8M8 16H6V14H8M4 4H2V2H4M4 8H2V6H4M4 12H2V10H4M4 16H2V14H4M10 4V1C10 0.447715 9.55229 0 9 0H1C0.447715 0 0 0.447715 0 1V17C0 17.5523 0.447715 18 1 18H19C19.5523 18 20 17.5523 20 17V5C20 4.44772 19.5523 4 19 4H10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BuildingIcon;
